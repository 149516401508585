var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.submitted)?_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"Success"}},[_c('b-button',{attrs:{"variant":"outline-primary","to":{ name: 'crm-utenti-index' }}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Lista Utenti ")],1)],1):_vm._e(),(_vm.errore_caricamento)?_c('b-card',{attrs:{"border-variant":"danger"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"8"}},[_c('h4',{staticClass:"mt-0 mb-0 text-danger"},[_vm._v("Errore durante il caricamento delle informazioni iniziali")]),_c('p',[_vm._v("Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie")])]),_c('b-col',{staticClass:"text-center",attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'crm-leads-index' }}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Leads ")],1)],1)],1)],1):_vm._e(),(_vm.Caricato == false)?_c('div',{staticClass:"text-center my-3"},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"loading"}})],1):_vm._e(),(_vm.Caricato)?_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Dettagli")])]},proxy:true}],null,false,2106675683)},[_c('b-card',{attrs:{"bg-variant":"Default"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Ragione Sociale *","label-for":"company_name"}},[_c('validation-provider',{attrs:{"name":"Ragione Sociale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.company_name),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "company_name", $$v)},expression:"campiform.billing.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2134037127)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.lead.name),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "name", $$v)},expression:"campiform.lead.name"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cognome *","label-for":"Surname"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.lead.surname),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "surname", $$v)},expression:"campiform.lead.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3097013484)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email dell'utente","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.lead.email),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "email", $$v)},expression:"campiform.lead.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3297597107)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Telefono","label-for":"telefono"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.lead.phone),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "phone", $$v)},expression:"campiform.lead.phone"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cellulare *","label-for":"Cellulare"}},[_c('validation-provider',{attrs:{"name":"Cellulare","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.lead.mobile_phone),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "mobile_phone", $$v)},expression:"campiform.lead.mobile_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4175955324)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Reseller *","label-for":"reseller_id"}},[_c('validation-provider',{attrs:{"name":"Reseller","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_user_reseller,"state":errors.length > 0 ? false:null},model:{value:(_vm.campiform.lead.reseller_id),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "reseller_id", $$v)},expression:"campiform.lead.reseller_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1799888161)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Assistente *","label-for":"assistant_id"}},[_c('validation-provider',{attrs:{"name":"Assistente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_user_assistente,"state":errors.length > 0 ? false:null},model:{value:(_vm.campiform.lead.assistant_id),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "assistant_id", $$v)},expression:"campiform.lead.assistant_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3094831040)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Valutazione","label-for":"evaluation_id"}},[_c('b-form-select',{attrs:{"options":_vm.options_evalution},model:{value:(_vm.campiform.lead.evaluation_id),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "evaluation_id", $$v)},expression:"campiform.lead.evaluation_id"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Rating","label-for":"rating"}},[_c('b-form-select',{attrs:{"options":_vm.options_rating},model:{value:(_vm.campiform.lead.rating),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "rating", $$v)},expression:"campiform.lead.rating"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Stato *","label-for":"status_id"}},[_c('validation-provider',{attrs:{"name":"Ruolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_status,"state":errors.length > 0 ? false:null},model:{value:(_vm.campiform.lead.status_id),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "status_id", $$v)},expression:"campiform.lead.status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1753964015)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Origine *","label-for":"source_id"}},[_c('validation-provider',{attrs:{"name":"Origine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_source,"state":errors.length > 0 ? false:null},model:{value:(_vm.campiform.lead.source_id),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "source_id", $$v)},expression:"campiform.lead.source_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3147351631)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sito web","label-for":"website"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.lead.website),callback:function ($$v) {_vm.$set(_vm.campiform.lead, "website", $$v)},expression:"campiform.lead.website"}})],1)],1)],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"LockIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Fatturazione")])]},proxy:true}],null,false,3779487565)},[_c('b-card',{attrs:{"bg-variant":"Default"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Nazione *","label-for":"country_id"}},[_c('validation-provider',{attrs:{"name":"Nazione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{ref:"autocomplete",attrs:{"suggestions":_vm.filteredOptions,"limit":8,"input-props":{id:'autosuggest__input',class:'form-control', placeholder:'cerca nazione', },"render-suggestion":_vm.renderSuggestion,"get-suggestion-value":_vm.getSuggestionValue},on:{"input":_vm.onInputChange,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.valoreDefaultNazione),callback:function ($$v) {_vm.valoreDefaultNazione=$$v},expression:"valoreDefaultNazione"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,964929116)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Indirizzo *","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Indirizzo","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"","state":errors.length > 0 ? false:null},model:{value:(_vm.campiform.billing.address),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "address", $$v)},expression:"campiform.billing.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3760786926)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cap *","label-for":"zip"}},[_c('validation-provider',{attrs:{"name":"Cap","rules":"required|digits:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.zip),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "zip", $$v)},expression:"campiform.billing.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2221293307)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Città *","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"Città","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.city),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "city", $$v)},expression:"campiform.billing.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2341335679)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Provincia *","label-for":"province_id"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.options_province},model:{value:(_vm.campiform.billing.province_id),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "province_id", $$v)},expression:"campiform.billing.province_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1436594344)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Partita IVA *","label-for":"vat_number"}},[_c('validation-provider',{attrs:{"name":"Partita IVA","rules":"regex:^([a-zA-Z0-9]+)$|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.vat_number),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "vat_number", $$v)},expression:"campiform.billing.vat_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,524323047)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Codice fiscale","label-for":"fiscal_code"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.billing.fiscal_code),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "fiscal_code", $$v)},expression:"campiform.billing.fiscal_code"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Pec","label-for":"pec"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.billing.pec),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "pec", $$v)},expression:"campiform.billing.pec"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Codice SDI","label-for":"dest_code"}},[_c('validation-provider',{attrs:{"name":"Codice SDI","rules":"length:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.dest_code),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "dest_code", $$v)},expression:"campiform.billing.dest_code"}})]}}],null,false,841558973)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Regime IVA *","label-for":"vat_scheme_id"}},[_c('validation-provider',{attrs:{"name":"Regime IVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.options_regine_iva},model:{value:(_vm.campiform.billing.vat_scheme_id),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "vat_scheme_id", $$v)},expression:"campiform.billing.vat_scheme_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2638278898)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Pagamenti","label-for":"v-adse-pagamenti"}},[_c('multiselect',{attrs:{"options":_vm.options_payment,"custom-label":_vm.multiSelect_act_Lingua,"placeholder":"seleziona o ricerca","multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"show-labels":false,"max-height":250,"max":5,"label":"pagamenti","track-by":"text"},model:{value:(_vm.campiform.payment.list),callback:function ($$v) {_vm.$set(_vm.campiform.payment, "list", $$v)},expression:"campiform.payment.list"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Nessun risultato")]),_c('span',{attrs:{"slot":"maxElements"},slot:"maxElements"},[_vm._v("Limite opzioni selezionabili raggiunto")])])],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm()}}},[_vm._v(" Salva e Converti ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }